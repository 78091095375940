import { NavLink } from "react-router-dom";
import { getRegisterObjectImageUrl } from "../stores/register/register";
import RouteUtils from "../utils/RouteUtils";
import { Authority } from "./authConstants";
import { ClassifierType, Domain, ObjectStatus } from "./classifierConstants";

const getRegisterObjectLink = (location, objectType, objectId, label) => {
  const url = RouteUtils.registerPathAwareRoute(location, `/view/${objectType}/${objectId}`)
  return <NavLink to={url}>{label}</NavLink>;
};

const getCareAreaRelatedObjectLink = (column, row, location) => {
  let objectType, objectId;
  if (row.roadId) {
    objectType = 'road';
    objectId = row.roadId;
  } else if (row.parkingLotId) {
    objectType = 'parkingLot';
    objectId = row.parkingLotId;
  } else if (row.crosswalkId) {
    objectType = 'crosswalk';
    objectId = row.crosswalkId;
  } else if (row.busStopId) {
    objectType = 'busStop';
    objectId = row.busStopId;
  }
  return getRegisterObjectLink(location, objectType, objectId, row[column.field]);
};

const getCareAreaLink = (column, row, location) => {
  return getRegisterObjectLink(location, 'careAreaTE', row.careArea.id, `${row.careArea.code} ${row.careArea.title}`);
};

export const RegisterObjectDefs = {
  //STREETLIGHT
  shieldRegion: {
    domain: Domain.streetlight,
    topLevelObject: true,
    genericAddNew: true,
    noWorkOrder: true,
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  electricShield: {
    domain: Domain.streetlight,
    authority: [Authority.UC31_register_object_data, Authority.UC31_view_restricted],
    mainParentObjectType: "shieldRegion",
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "shieldRegion", type: "relation", relation: "shieldRegion", relationField: "title", detailsOnly: true },
      { field: "shieldType", type: "classifier", classifierType: ClassifierType.shieldType, filter: true },
      { field: "switchingMethod", detailsOnly: true, type: "classifier", classifierType: ClassifierType.switchingMethod },
      { field: "ampereRating", type: "integer", unit: "A" },
      { field: "connectionPhases", detailsOnly: true, type: "classifier", classifierType: ClassifierType.connectionPhases },
      { field: "feederNumber", detailsOnly: true, type: "classifier", classifierType: ClassifierType.feederNumber },
      { field: "safetyClass", detailsOnly: true },
      { field: "model", detailsOnly: true },
      { field: "shieldOwnership", detailsOnly: true, type: "classifier", classifierType: ClassifierType.shieldOwnership },
      { field: "shieldPosition", detailsOnly: true, type: "classifier", classifierType: ClassifierType.shieldPosition },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "locationGeometry", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  cable: {
    domain: Domain.streetlight,
    joinable: true,
    mainParentObjectType: "shieldRegion",
    columns: [
      { field: "code", readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "shieldRegion", type: "relation", relation: "shieldRegion", relationField: "title", detailsOnly: true },
      { field: "cablingType", type: "classifier", classifierType: ClassifierType.cablingType, filter: true, required: true },
      { field: "cableMark", detailsOnly: true, type: "classifier", classifierType: ClassifierType.cableMark },
      { field: "length", detailsOnly: true, type: "number", unit: "m", readonly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "locationGeometry", tableOnly: true, type: "geometry", showMapButton: true, geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  post: {
    domain: Domain.streetlight,
    mainParentObjectType: "shieldRegion",
    columns: [
      { field: "code", readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "shieldRegion", type: "relation", relation: "shieldRegion", relationField: "title", detailsOnly: true },
      { field: "postType", type: "classifier", classifierType: ClassifierType.postType, filter: true },
      { field: "feederNumber", detailsOnly: true, type: "classifier", classifierType: ClassifierType.feederNumber },
      { field: "supportType", detailsOnly: true, type: "classifier", classifierType: ClassifierType.supportType },
      { field: "hasSupport", detailsOnly: true, type: "boolean" },
      { field: "hasSpreader", detailsOnly: true, type: "boolean" },
      { field: "ownership", detailsOnly: true, type: "classifier", classifierType: ClassifierType.ownership },
      { field: "height", unit: "m", type: "integer" },
      { field: "hasCarrier", detailsOnly: true, type: "boolean" },
      { field: "carrierLength", detailsOnly: true, unit: "m", type: "number" },
      { field: "finishingType", detailsOnly: true, type: "classifier", classifierType: ClassifierType.finishingType },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "locationGeometry", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  lamp: {
    domain: Domain.streetlight,
    mainParentObjectType: "shieldRegion",
    columns: [
      { field: "code", readonly: true, filter: true },
      { field: "post", type: "relation", relation: "post", relationField: "title" },
      { field: "shieldRegion", type: "relation", relation: "shieldRegion", relationField: "title", detailsOnly: true },
      { field: "lampType", type: "classifier", classifierType: ClassifierType.lampType, filter: true, required: true },
      { field: "lampPurpose", type: "classifier", classifierType: ClassifierType.lampPurpose, filter: true },
      { field: "controlType", detailsOnly: true, type: "classifier", classifierType: ClassifierType.controlType },
      { field: "power", unit: "W", type: "number" },
      { field: "model", detailsOnly: true },
      { field: "ownership", detailsOnly: true, type: "classifier", classifierType: ClassifierType.ownership },
      { field: "installationHeight", detailsOnly: true, unit: "m", type: "number" },
      { field: "position", detailsOnly: true, type: "classifier", classifierType: ClassifierType.position },
      { field: "lampCondition", type: "classifier", classifierType: ClassifierType.lampCondition, filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", detailsOnly: true, readonly: true },
      { field: "locationGeometry", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
      { field: "addClosestPostRelation", type: "boolean", detailsOnly: true, tableOnly: true },
    ]
  },
  //ROAD
  road: {
    domain: Domain.road,
    topLevelObject: true,
    genericAddNew: true,
    sort: { field: "title", ascending: true },
    columns: [
      { field: "code", readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "lengthM", type: "number", unit: "m", readonly: true },
      { field: "length3d", detailsOnly: true, type: "number", unit: "m" },
      { field: "roadType", type: "classifier", classifierType: ClassifierType.roadKind, filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "firstEstablishmentYear", detailsOnly: true, type: "integer" },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", detailsOnly: true, readonly: true },
      { field: "areaShape", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
      { 
        field: "surfaces", detailsOnly: true, hideOnForm: true, type: "relatedTable", relation: "roadSurface", 
        relationFields: ['coatingType', 'conditionLevelSummer', 'conditionLevelWinter'],
        relationFilter: (o) => (ObjectStatus.valid === o.status)
      }
    ]
  },
  roadSection: {
    domain: Domain.road,
    divider: true,
    sort: [
      { field: "road.title", ascending: true },
      { field: "roadSectionNr", ascending: true }
    ],
    columns: [
      { field: "roadCode", type: "relation", relation: "road", relationField: "code", filter: true },
      { field: "road", type: "relation", relation: "road", relationField: "title", includeInMapDialog: true, filter: true },
      { field: "roadSectionNr", readonly: true, filter: true, type: "integer" },
      { field: "startM", type: "number", unit: "m" },
      { field: "endM", type: "number", unit: "m" },
      { field: "lengthM", type: "number", unit: "m", readonly: true },
      { field: "length3d", detailsOnly: true, type: "number", unit: "m" },
      { field: "roadWidth", detailsOnly: true, type: "number", unit: "m", decimals: 1 },
      { field: "roadSectionType", type: "classifier", classifierType: ClassifierType.roadSectionType, filter: true },
      { field: "roadType", type: "classifier", classifierType: ClassifierType.roadType, filter: true },
      { field: "roadBreakdown", type: "classifier", classifierType: ClassifierType.roadBreakdown, filter: true },
      { field: "purpose", readonly: true, detailsOnly: true, },
      { field: "maintenanceGroup", type: "classifier", classifierType: ClassifierType.maintenanceGroup, detailsOnly: true },
      { field: "cadastralCode", filter: true },
      { field: "nearbyAddress", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", detailsOnly: true, readonly: true },
      { field: "startpoint", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "endpoint", detailsOnly: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  roadSurface: {
    domain: Domain.road,
    noRelatedObjects: true,
    sort: [
      { field: "road.title", ascending: true },
      { field: "startM", ascending: true }
    ],
    columns: [
      { field: "roadCode", type: "relation", relation: "road", relationField: "code", filter: true },
      { field: "road", type: "relation", relation: "road", relationField: "title", includeInMapDialog: true, filter: true },
      { field: "startM", type: "number", unit: "m", decimals: 2 },
      { field: "endM", type: "number", unit: "m", decimals: 2 },
      { field: "lengthM", type: "number", unit: "m", readonly: true, decimals: 2 },
      { field: "length3d", detailsOnly: true, type: "number", unit: "m", decimals: 2 },
      { field: "surfaceWidth", type: "number", unit: "m", decimals: 1 },
      { field: "coatingType", type: "classifier", classifierType: ClassifierType.coatingType, filter: true },
      { field: "conditionLevelSummer", type: "classifier", classifierType: ClassifierType.conditionLevelSummer, filter: true },
      { field: "conditionLevelWinter", type: "classifier", classifierType: ClassifierType.conditionLevelWinter, filter: true },
      { field: "surfaceYear", type: "integer", filter: true },
      { field: "coatingLayering", type: "classifier", classifierType: ClassifierType.coatingLayering, detailsOnly: true },
      { field: "overcoatingYear", type: "integer", detailsOnly: true },
      { field: "lastMaintenanceYear", type: "integer", detailsOnly: true },
      { field: "activityType", type: "classifier", classifierType: ClassifierType.activityType, detailsOnly: true },
      { field: "hasRoadside", detailsOnly: true, type: "boolean" },
      { field: "roadsideWidthLeft", detailsOnly: true, type: "number", unit: "m", decimals: 1 },
      { field: "roadsideWidthRight", detailsOnly: true, type: "number", unit: "m", decimals: 1 },
      { field: "roadCondition", type: "classifier", classifierType: ClassifierType.roadCondition, detailsOnly: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "startpoint", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "endpoint", detailsOnly: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  stateException: {
    domain: Domain.road,
    noRelatedObjects: true,
    divider: true,
    noValidButton: true,
    columns: [
      { field: "road", type: "relation", relation: "road", relationField: "title", includeInMapDialog: true, filter: true },
      { field: "startM", type: "number", unit: "m" },
      { field: "endM", type: "number", unit: "m" },
      { field: "conditionLevelWinter", type: "classifier", classifierType: ClassifierType.conditionLevelWinter, filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "startpoint", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "endpoint", detailsOnly: true, type: "geometry", geometryTypes: ["Point"] },
    ]
  },
  busStop: {
    domain: Domain.road,
    sort: { field: "title", ascending: true },
    columns: [
      { field: "ytrisCode", filter: true },
      { field: "title", required: true, filter: true },
      { field: "road", type: "relation", relation: "road", relationField: "title", filter: true },
      { field: "transportType", type: "classifier", classifierType: ClassifierType.transportType, detailsOnly: true },
      { field: "priceZone", filter: true },
      { field: "busStopType", type: "classifier", classifierType: ClassifierType.busStopType, detailsOnly: true },
      { field: "positionSide", type: "classifier", classifierType: ClassifierType.positionSide, filter: true },
      { field: "platformType", type: "classifier", classifierType: ClassifierType.platformType, detailsOnly: true },
      { field: "waitingRoomType", type: "classifier", classifierType: ClassifierType.waitingRoomType, filter: true },
      { field: "hasBusPocket", detailsOnly: true, type: "boolean" },
      { field: "hasTrashCan", detailsOnly: true, type: "boolean" },
      { field: "hasBench", detailsOnly: true, type: "boolean" },
      { field: "travelDirection", type: "classifier", classifierType: ClassifierType.travelDirection, detailsOnly: true },
      { field: "holderType", type: "classifier", classifierType: ClassifierType.holderType, detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "coordinates", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  parkingLot: {
    domain: Domain.road,
    columns: [
      { field: "code", type: 'integer', readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "roadSection", type: "relation", relation: "roadSection", relationField: "roadSectionNr", detailsOnly: true },
      { field: "parkingLotType", type: "classifier", classifierType: ClassifierType.parkingLotType, filter: true },
      { field: "parkingLotSide", type: "classifier", classifierType: ClassifierType.parkingLotSide, detailsOnly: true },
      { field: "area", type: "number", unit: 'm\u00B2', readonly: true },
      { field: "parkingSpotsCount", type: "integer", filter: true },
      { field: "cadastralCode", type: "relation", relation: "roadSection", relationField: "cadastralCode", filter: true },
      { field: "nearbyAddress", type: "relation", relation: "roadSection", relationField: "nearbyAddress", filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", detailsOnly: true, readonly: true },
      { field: "areaShape", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Polygon"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  crosswalk: {
    domain: Domain.road,
    columns: [
      { field: "code", type: 'integer', readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "roadSection", type: "relation", relation: "roadSection", relationField: "roadSectionNr", detailsOnly: true },
      { field: "crosswalkType", type: "classifier", classifierType: ClassifierType.crosswalkType, detailsOnly: true },
      { field: "crosswalkMarking", type: "classifier", classifierType: ClassifierType.crosswalkMarking, detailsOnly: true },
      { field: "cadastralCode", type: "relation", relation: "roadSection", relationField: "cadastralCode", filter: true },
      { field: "nearbyAddress", type: "relation", relation: "roadSection", relationField: "nearbyAddress", filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", detailsOnly: true, readonly: true },
      { field: "areaShape", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Polygon"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  railwayCrossing: {
    domain: Domain.road,
    columns: [
      { field: "code", type: 'integer', readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "roadSection", type: "relation", relation: "roadSection", relationField: "roadSectionNr", detailsOnly: true },
      { field: "railwayCrossingType", type: "classifier", classifierType: ClassifierType.railwayCrossingType, filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "areaShape", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Polygon"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  bridge: {
    domain: Domain.road,
    divider: true,
    columns: [
      { field: "code", type: 'integer', readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "roadSection", type: "relation", relation: "roadSection", relationField: "roadSectionNr", detailsOnly: true },
      { field: "bridgeType", type: "classifier", classifierType: ClassifierType.bridgeType, filter: true },
      { field: "overcomeObstacle", detailsOnly: true },
      { field: "normativeLoadCapacity", detailsOnly: true, type: "number", unit: "t" },
      { field: "lengthM", detailsOnly: true, type: "number", unit: "m" },
      { field: "lengthBetweenExpansionJoints", detailsOnly: true, type: "number", unit: "m" },
      { field: "widthM", detailsOnly: true, type: "number", unit: "m" },
      { field: "maxAxleLoad", detailsOnly: true, type: "number", unit: "t" },
      { field: "maxVehicleWeight", detailsOnly: true, type: "number", unit: "t" },
      { field: "renovationYear", type: "integer", filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "areaShape", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  restriction: {
    domain: Domain.road,
    columns: [
      { field: "code", type: 'integer', readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "road", type: "relation", relation: "road", relationField: "title", detailsOnly: true },
      { field: "restrictionType", type: "classifier", classifierType: ClassifierType.restrictionType, filter: true },
      { field: "valueRight", type: "number", filter: true },
      { field: "timePeriodRight", filter: true },
      { field: "valueLeft", type: "number", filter: true },
      { field: "timePeriodLeft", filter: true },
      { field: "additionalBoardLeft", type: "classifier", classifierType: ClassifierType.additionalBoardLeft, detailsOnly: true },
      { field: "additionalBoardRight", type: "classifier", classifierType: ClassifierType.additionalBoardRight, detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "areaShape", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["LineString", "Polygon"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  roadSign: {
    domain: Domain.road,
    columns: [
      { field: "code", type: 'integer', readonly: true, filter: true },
      { field: "road", type: "relation", relation: "road", relationField: "title", detailsOnly: true },
      { field: "restriction", type: "relation", relation: "restriction", relationField: "title", detailsOnly: true },
      { field: "crosswalk", type: "relation", relation: "crosswalk", relationField: "title", detailsOnly: true },
      { field: "railwayCrossing", type: "relation", relation: "railwayCrossing", relationField: "title", detailsOnly: true },
      { field: "bridge", type: "relation", relation: "bridge", relationField: "title", detailsOnly: true },
      { field: "parkingLot", type: "relation", relation: "parkingLot", relationField: "title", detailsOnly: true },
      {
        field: "roadSignKindCode", type: "relation", relation: "roadSignKind", relationField: "code", includeInMapDialog: true,
        useAutocomplete: true, filter: true,
        autocompleteLabel: (o) => `${o.code} ${o.title}`,
        autocompleteGroup: (o) => o.roadSignKindGroup
      },
      {
        field: "roadSignKindTitle", type: "relation", relation: "roadSignKind", relationField: "title",
        hideOnForm: true, includeInMapDialog: true, filter: true
      },
      {
        field: "roadSignKindGroup", type: "relation", relation: "roadSignKind", relationField: "roadSignKindGroup",
        hideOnForm: true, includeInMapDialog: true, filter: true
      },
      { field: "roadSignKindDescription", type: "relation", relation: "roadSignKind", relationField: "description", hideOnForm: true },
      {
        field: "roadSignKindImage", type: "relation", relation: "roadSignKind", relationField: "image",
        hideOnForm: true, includeInMapDialog: true, notSortable: true,
        renderCell: (column, row) => {
          return (!!row.roadSignKind || !!row.roadSignKindId) && <img src={getRegisterObjectImageUrl(column.relation, row.roadSignKindId || row.roadSignKind.id)} height={46} alt={row.roadSignKind?.title} />;
        },
        renderDetails: (column, row) => {
          return !!row.roadSignKind && <img src={getRegisterObjectImageUrl(column.relation, row.roadSignKind.id)} alt={row.roadSignKind.title} />;
        }
      },
      { field: "valueRight", type: "integer", filter: true, detailsOnly: true },
      { field: "timePeriodRight", detailsOnly: true },
      { field: "valueLeft", type: "integer", filter: true, detailsOnly: true },
      { field: "timePeriodLeft", detailsOnly: true },
      { field: "carrierType", type: "classifier", classifierType: ClassifierType.carrierType, detailsOnly: true },
      { field: "locationSide", type: "classifier", classifierType: ClassifierType.locationSide, detailsOnly: true },
      { field: "sizeGroup", type: "classifier", classifierType: ClassifierType.sizeGroup, detailsOnly: true },
      { field: "lightReflectivity", type: "classifier", classifierType: ClassifierType.lightReflectivity, detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, detailsOnly: true },
      { field: "locationGeometry", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "influence", detailsOnly: true, tableOnly: true, type: "geometry", geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  trafficFrequency: {
    domain: Domain.road,
    noRelatedObjects: true,
    noValidButton: true,
    notInsertable: true,
    notUpdatable: true,
    notDeletable: true,
    sort: { field: "road.title", ascending: true },
    columns: [
      { field: "code", readonly: true, detailsOnly: true, type: "integer" },
      { field: "road", type: "relation", relation: "road", relationField: "title", filter: true },
      { field: "year", type: "integer", filter: true },
      { field: "startKm", type: "number", unit: "km", decimals: 3 },
      { field: "endKm", type: "number", unit: "km", decimals: 3 },
      { field: "akolYearAverageDailyTraffic", type: "integer" },
      { field: "sapaarvCarAndVanCount", type: "integer" },
      { field: "vabarvTruckAndBusCount", type: "integer" },
      { field: "ararvCartrainCount", type: "integer" },
      { field: "perspective", type: "integer", detailsOnly: true },
      { field: "perspectiveYear", type: "integer", detailsOnly: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, detailsOnly: true },
      { field: "geometry", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  countingPoint: {
    domain: Domain.road,
    noRelatedObjects: true,
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "road", type: "relation", relation: "road", relationField: "title", filter: true },
      { field: "countingPointType", type: "classifier", classifierType: ClassifierType.countingPointType, filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "coordinates", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  //RAINWATER
  rainwaterRoute: {
    domain: Domain.rainwater,
    noValidButton: true,
    notInsertable: true,
    notDeletable: true,
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "drainageBasin", type: "relation", relation: "drainageBasin", relationField: "title" },
      { field: "strengtheningType", type: "classifier", classifierType: ClassifierType.pipeOutletStrengtheningType, filter: true },
      { field: "strengtheningYear", detailsOnly: true, type: "integer", filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", type: "date" },
      { field: "establishmentYear", detailsOnly: true, type: "integer", filter: true },
      { field: "intakeReservoirCoordinates", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  drainageBasin: {
    domain: Domain.rainwater,
    noValidButton: true,
    notInsertable: true,
    notDeletable: true,
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "drainageBasinType", type: "classifier", classifierType: ClassifierType.drainageBasinType, filter: true },
      { field: "drainageBasinKind", type: "classifier", classifierType: ClassifierType.drainageBasinKind, filter: true },
      { field: "area", type: "number", unit: 'm\u00B2', readonly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "location", readonly: true, filter: true },
      { field: "areaShape", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Polygon"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  pipe: {
    domain: Domain.rainwater,
    joinable: true,
    mainParentObjectType: "rainwaterRoute",
    columns: [
      {
        field: "rainWaterCodeAction", type: "classifier", classifierType: ClassifierType.rainwaterCodeAction, required: true,
        detailsOnly: true, tableOnly: true, showInsertOnly: true
      },
      { field: "code", readonly: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title" },
      { field: "pipeType", type: "classifier", classifierType: ClassifierType.pipeType, filter: true, required: true },
      { field: "pipeMaterial", type: "classifier", classifierType: ClassifierType.pipeMaterial, detailsOnly: true },
      { field: "startHeight", type: "number", unit: "m", detailsOnly: true },
      { field: "endHeight", type: "number", unit: "m", detailsOnly: true },
      { field: "diameter", type: "integer", unit: "mm", detailsOnly: true },
      { field: "length", readonly: true, detailsOnly: true, type: "number", unit: "m" },
      { field: "isUpstream", detailsOnly: true, type: "boolean" },
      { field: "lastMaintenanceDate", type: "date", detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", type: "date" },
      { field: "establishmentYear", detailsOnly: true, type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "direction", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  well: {
    domain: Domain.rainwater,
    mainParentObjectType: "rainwaterRoute",
    columns: [
      { field: "code", readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title" },
      { field: "wellType", type: "classifier", classifierType: ClassifierType.wellType, filter: true, required: true },
      { field: "wellKind", type: "classifier", classifierType: ClassifierType.wellKind, filter: true },
      { field: "wellMaterial", type: "classifier", classifierType: ClassifierType.wellMaterial, detailsOnly: true },
      { field: "bottomHeight", type: "number", unit: "m", detailsOnly: true },
      { field: "diameter", type: "number", unit: "mm", detailsOnly: true },
      { field: "pipesNumberIn", type: "integer", detailsOnly: true },
      { field: "pipesNumberOut", type: "integer", detailsOnly: true },
      { field: "lastMaintenanceDate", type: "date", detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", type: "date" },
      { field: "establishmentYear", detailsOnly: true, type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "centralPoint", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  ditch: {
    domain: Domain.rainwater,
    joinable: true,
    mainParentObjectType: "rainwaterRoute",
    columns: [
      {
        field: "rainWaterCodeAction", type: "classifier", classifierType: ClassifierType.rainwaterCodeAction, required: true,
        detailsOnly: true, tableOnly: true, showInsertOnly: true
      },
      { field: "code", readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title" },
      { field: "ditchType", type: "classifier", classifierType: ClassifierType.ditchType, filter: true, required: true },
      { field: "depth", type: "number", unit: "m", detailsOnly: true },
      { field: "bottomWidth", type: "number", unit: "m", detailsOnly: true },
      { field: "topWidth", type: "number", unit: "m", detailsOnly: true },
      { field: "edges", detailsOnly: true },
      { field: "length", readonly: true, detailsOnly: true, type: "number", unit: "m" },
      { field: "isUpstream", detailsOnly: true, type: "boolean" },
      { field: "lastMaintenanceDate", type: "date", detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", type: "date" },
      { field: "establishmentYear", detailsOnly: true, type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "direction", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  culvert: {
    domain: Domain.rainwater,
    joinable: true,
    mainParentObjectType: "rainwaterRoute",
    divider: true,
    columns: [
      {
        field: "rainWaterCodeAction", type: "classifier", classifierType: ClassifierType.rainwaterCodeAction, required: true,
        detailsOnly: true, tableOnly: true, showInsertOnly: true
      },
      { field: "code", readonly: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title" },
      { field: "culvertMaterial", type: "classifier", classifierType: ClassifierType.culvertMaterial, detailsOnly: true },
      { field: "startHeight", type: "number", unit: "m", detailsOnly: true },
      { field: "endHeight", type: "number", unit: "m", detailsOnly: true },
      { field: "diameter", type: "integer", unit: "mm", detailsOnly: true },
      { field: "length", readonly: true, detailsOnly: true, type: "number", unit: "m" },
      { field: "isUpstream", detailsOnly: true, type: "boolean" },
      { field: "lastMaintenanceDate", type: "date", detailsOnly: true },
      { field: "strengtheningStart", type: "classifier", classifierType: ClassifierType.pipeOutletStrengtheningType, detailsOnly: true },
      { field: "strengtheningStartYear", detailsOnly: true, type: "integer" },
      { field: "strengtheningEnd", type: "classifier", classifierType: ClassifierType.pipeOutletStrengtheningType, detailsOnly: true },
      { field: "strengtheningEndYear", detailsOnly: true, type: "integer" },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", type: "date" },
      { field: "establishmentYear", detailsOnly: true, type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "direction", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["LineString"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  cleaner: {
    domain: Domain.rainwater,
    mainParentObjectType: "rainwaterRoute",
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title", detailsOnly: true },
      { field: "cleanerType", type: "classifier", classifierType: ClassifierType.cleanerType },
      { field: "model", detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "coordinates", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  flowmeter: {
    domain: Domain.rainwater,
    mainParentObjectType: "rainwaterRoute",
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title", detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "coordinates", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  waterQualityMeter: {
    domain: Domain.rainwater,
    mainParentObjectType: "rainwaterRoute",
    columns: [
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title", detailsOnly: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", detailsOnly: true, type: "date" },
      { field: "establishmentYear", type: "integer", filter: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "coordinates", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  pumpingStation: {
    domain: Domain.rainwater,
    mainParentObjectType: "rainwaterRoute",
    columns: [
      { field: "code", readonly: true, filter: true },
      { field: "title", required: true, filter: true },
      { field: "rainwaterRoute", type: "relation", relation: "rainwaterRoute", relationField: "title", detailsOnly: true },
      { field: "bottomHeight", type: "number", unit: "m", detailsOnly: true },
      { field: "diameter", type: "number", unit: "mm", detailsOnly: true },
      { field: "pipesNumberIn", type: "integer", detailsOnly: true },
      { field: "pipesNumberOut", type: "integer", detailsOnly: true },
      { field: "lastMaintenanceDate", type: "date", detailsOnly: true },
      { field: "power", unit: "W", type: "integer" },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentDate", type: "date" },
      { field: "establishmentYear", detailsOnly: true, type: "integer" },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "centralPoint", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  //BASIC OBJECT
  basicObject: {
    superObjectType: "basicObject",
    domain: null,
    genericAddNew: true,
    noRelatedObjects: true,
    noHistory: true,
    noValidButton: true,
    columns: [
      { field: "basicObjectType", detailsOnly: true, type: "relation", relation: "basicObjectType", relationField: "title", relationFieldEng: "titleEng" },
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      //tüüp dünaamiliselt
      { field: "installationMethod", type: "classifier", classifierType: ClassifierType.installationMethod, filter: true },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "establishmentYear", type: "integer", filter: true, detailsOnly: true },
      { field: "location", readonly: true, filter: true },
      //geomeetria dünaamiliselt
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  //SERVITUDE
  servitudeArea: {
    domain: "servitude",
    authority: [Authority.UC31_register_object_data],
    genericAddNew: true,
    noRelatedAdd: true,
    sortByIndex: 2,
    noWorkOrder: true,
    columns: [
      { field: "domain", type: "classifier", classifierType: ClassifierType.domain, filter: true },
      { field: "code", readonly: true, filter: true, type: "integer" },
      { field: "title", required: true, filter: true },
      { field: "servitudeType", type: "classifier", classifierType: ClassifierType.servitudeType, filter: true },
      { field: "areaM", type: "integer", unit: 'm\u00B2', readonly: true },
      { field: "isInKovFavor", type: "boolean", filter: true },
      { field: "settingDate", detailsOnly: true, type: "date" },
      { field: "validToDate", detailsOnly: true, type: "date" },
      { field: "registrationDate", type: "date", hideOnForm: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "location", readonly: true, filter: true },
      { field: "area", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: ["MultiPolygon"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  "careAreaSV": {
    superObjectType: "careArea",
    domain: Domain.rainwater,
    genericAddNew: true,
    noWorkOrder: true,
    notInsertable: true,
    noRelatedObjects: true,
    columns: [
      { field: "code", required: true, filter: true, notUpdateable: true },
      { field: "title", required: true, filter: true },
      { field: "maintenanceContractName", required: true, filter: true },
      {
        field: "cooperationPartnerCompany", required: true, filter: true, type: "relation",
        relation: "cooperationPartnerCompany", relationField: "title",
        useAutocomplete: true, autocompleteLabel: (o) => o.title
      },
      { field: "subpartner", filter: true },
      { field: "registrationDate", type: "date", hideOnForm: true, detailsOnly: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  "careAreaTV": {
    superObjectType: "careArea",
    domain: Domain.streetlight,
    genericAddNew: true,
    noWorkOrder: true,
    notInsertable: true,
    noRelatedObjects: true,
    columns: [
      { field: "code", required: true, filter: true, notUpdateable: true },
      { field: "title", required: true, filter: true },
      { field: "maintenanceContractName", required: true, filter: true },
      {
        field: "cooperationPartnerCompany", required: true, filter: true, type: "relation",
        relation: "cooperationPartnerCompany", relationField: "title",
        useAutocomplete: true, autocompleteLabel: (o) => o.title
      },
      { field: "subpartner", filter: true },
      { field: "registrationDate", type: "date", hideOnForm: true, detailsOnly: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  "careAreaTE": {
    superObjectType: "careArea",
    domain: Domain.road,
    genericAddNew: true,
    noWorkOrder: true,
    notInsertable: true,
    topLevelObject: true,
    columns: [
      { field: "code", required: true, filter: true, notUpdateable: true },
      { field: "title", required: true, filter: true },
      { field: "careActionType", type: "classifier", classifierType: ClassifierType.careActionType, filter: true },
      { field: "maintenanceContractName", required: true, filter: true },
      {
        field: "cooperationPartnerCompany", required: true, filter: true, type: "relation",
        relation: "cooperationPartnerCompany", relationField: "title",
        useAutocomplete: true, autocompleteLabel: (o) => o.title
      },
      { field: "subpartner", filter: true },
      { field: "registrationDate", type: "date", hideOnForm: true, detailsOnly: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  "careAreaObject": {
    domain: Domain.road,
    notInsertable: false,
    noRelatedObjects: true,
    noDocuments: true,
    notCopyable: true,
    columns: [
      { field: "id", type: "number", readonly: true, filter: true, hideOnForm: true },
      { field: "road", type: "relation", relation: "road", relationField: "title", detailsOnly: true, tableOnly: true, showInsertOnly: true },
      { field: "parkingLot", type: "relation", relation: "parkingLot", relationField: "title", detailsOnly: true, tableOnly: true, showInsertOnly: true },
      { field: "crosswalk", type: "relation", relation: "crosswalk", relationField: "title", detailsOnly: true, tableOnly: true, showInsertOnly: true },
      { field: "busStop", type: "relation", relation: "busStop", relationField: "title", detailsOnly: true, tableOnly: true, showInsertOnly: true },
      { field: "title", required: true, filter: true },
      {
        field: "careArea", type: "relation", relation: "careArea", relationField: "code", filter: true,
        renderCell: (column, row) => row.careArea.code, renderDetails: getCareAreaLink,
        useAutocomplete: true, autocompleteLabel: (o) => `${o.code} ${o.title}`, required: true,
      },
      { field: "relatedCode", readonly: true, renderDetails: getCareAreaRelatedObjectLink, showUpdateOnly: true, detailsOnly: true },
      { field: "relatedTitle", readonly: true, renderDetails: getCareAreaRelatedObjectLink, showUpdateOnly: true, detailsOnly: true },
      {
        field: "careActionType", type: "classifier", classifierType: ClassifierType.careActionType, notSortable: true, hideOnForm: true,
        filter: true, customFilter: (value) => `careArea_careActionType:${value.join(`,'careArea_careActionType:`)}`
      },
      { field: "areaBased", detailsOnly: true, type: "boolean" },
      { field: "stair", filter: true, type: "boolean" },
      { field: "stairWidth", detailsOnly: true, type: "number", unit: "m" },
      { field: "startM", detailsOnly: true, type: "number", unit: "m" },
      { field: "endM", detailsOnly: true, type: "number", unit: "m" },
      { field: "careLengthM", readonly: true, type: "number", unit: "m", hideOnForm: true, },
      { field: "careAreaM", readonly: true, type: "number", unit: "m\u00B2", hideOnForm: true, },
      { field: "registrationDate", detailsOnly: true, type: "date", hideOnForm: true },
      { field: "status", readonly: true, type: "classifier", classifierType: ClassifierType.objectStatus, filter: true },
      { field: "area", showMapButton: true, type: "geometry", geometryTypes: ["MultiPolygon"] },
      { field: "startpoint", detailsOnly: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "endpoint", detailsOnly: true, type: "geometry", geometryTypes: ["Point"] },
      { field: "note", detailsOnly: true, type: "textarea" },
    ]
  },
  //streetview only for map features
  streetU: {
    columns: [
      { field: "url" }
    ]
  },
  eyeVi: {
    columns: [
      { field: "url" }
    ]
  }
};