import React, { useEffect } from 'react';
import PageContainer from '../parts/PageContainer';
import WorkOrderTable from '../components/workOrder/WorkOrderTable';
import { Domain } from '../constants/classifierConstants';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Authority } from '../constants/authConstants';
import WorkOrderForm from '../components/workOrder/WorkOrderForm';
import WorkOrderView from '../components/workOrder/WorkOrderView';
import WorkOrderFulfilForm from '../components/workOrder/WorkOrderFulfilForm';
import AuthUtils from '../utils/AuthUtils';
import WorkOrderTablePartner from '../components/workOrder/WorkOrderTablePartner';
import RouteUtils from '../utils/RouteUtils';
import SecureWrapper from '../components/auth/SecureWrapper';

const allowedDomains = [Domain.rainwater, Domain.streetlight, Domain.road, Domain.landscaping, Domain.infrastructure];

export default function WorkOrder() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);

  useEffect(() => {
    RouteUtils.setDocumentTitle('workOrder');
  }, []);

  if (AuthUtils.hasAuthority(authUser, Authority.UC27_partner_work_order)) {
    if (location.pathname !== '/workOrder' && location.pathname !== '/workOrder/archive' &&
      !location.pathname.startsWith('/workOrder/view') && !location.pathname.startsWith('/workOrder/fulfil')) {
      return <Navigate to="/workOrder" />;
    }
    return <WorkOrderPartner />;
  }

  if (location.pathname === '/workOrder' || location.pathname === '/workOrder/') {
    const defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    return <Navigate to={`/workOrder/${defaultDomain}`} />
  }

  return <PageContainer>
    <Routes>
      <Route path={Domain.rainwater} element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderTable domain={Domain.rainwater} />
        </SecureWrapper>
      }/>
      <Route path={Domain.streetlight} element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderTable domain={Domain.streetlight} />
        </SecureWrapper>
      }/>
      <Route path={Domain.road} element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderTable domain={Domain.road} />
        </SecureWrapper>
      }/>
      <Route path={Domain.infrastructure} element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderTable domain={Domain.infrastructure} />
        </SecureWrapper>
      }/>
      <Route path={Domain.landscaping} element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderTable domain={Domain.landscaping} />
        </SecureWrapper>
      }/>
      <Route path={Domain.environment} element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderTable domain={Domain.environment} />
        </SecureWrapper>
      }/>
      <Route path="archive" element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderTable domain="archive" />
        </SecureWrapper>
      }/>
      <Route path="/edit/:id?" element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderForm />
        </SecureWrapper>
      }/>
      <Route path="/view/:id/*" element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderView />
        </SecureWrapper>
      }/>
      <Route path="/fulfil/:id" element={
        <SecureWrapper authority={Authority.UC26_work_orders}>
          <WorkOrderFulfilForm />
        </SecureWrapper>
      }/>
    </Routes>
  </PageContainer>;
}

function WorkOrderPartner() {
  return <PageContainer>
    <Routes>
      <Route path="/" element={
        <SecureWrapper authority={Authority.UC27_partner_work_order}>
          <WorkOrderTablePartner/>
        </SecureWrapper>
      }/>
      <Route path="/archive" element={
        <SecureWrapper authority={Authority.UC27_partner_work_order}>
          <WorkOrderTablePartner isArchive />
        </SecureWrapper>
      }/>
      <Route path="/view/:id/*" element={
        <SecureWrapper authority={Authority.UC27_partner_work_order}>
          <WorkOrderView/>
        </SecureWrapper>
      }/>
      <Route path="/fulfil/:id" element={
        <SecureWrapper authority={Authority.UC27_partner_work_order}>
          <WorkOrderFulfilForm/>
        </SecureWrapper>
      }/>
    </Routes>
  </PageContainer>;
}