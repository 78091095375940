import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Domain } from '../../constants/classifierConstants';
import NavigationTabs from '../table/NavigationTabs';

export default function WorkOrderTabs() {
  const { t } = useTranslation();

  const renderNavLink = (domain) => <NavLink to={`/workOrder/${domain}`}>{t(`workOrder.tabs.${domain}`)}</NavLink>;
  return <NavigationTabs>
    {renderNavLink(Domain.rainwater)}
    {renderNavLink(Domain.streetlight)}
    {renderNavLink(Domain.road)}
    {renderNavLink(Domain.infrastructure)}
    {renderNavLink(Domain.landscaping)}
    {renderNavLink(Domain.environment)}
    {renderNavLink("archive")}
  </NavigationTabs>;
}