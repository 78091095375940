import { Button, Grid, makeStyles, MenuItem, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Icon, Colors } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import { add, fetchBasicObjectTypes, save } from '../../stores/admin/basicObjectType';
import ClassifierSelect from '../form/ClassifierSelect';
import DialogForm from '../form/DialogForm';
import VTextField from '../form/VTextField';
import { useNavigate } from 'react-router-dom';
import VCheckbox from '../form/VCheckbox';
import ControlledSelect from '../form/ControlledSelect';
import { fetchClassifierTypes } from '../../stores/admin/classifier';

const useStyles = makeStyles({
  formBlock: {
    maxWidth: 846,
    margin: 'auto',
    padding: 25
  },
});

export default function BasicObjectTypeForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { selected, isLoading } = useSelector(state => state.basicObjectType);
  const { types } = useSelector(state => state.classifier);

  const { register, control, errors, handleSubmit, reset } = useForm({
    defaultValues: selected
  });
  const classes = useStyles();
  const navigate = useNavigate();

  const [shouldRefreshTable, setShouldRefreshTable] = useState(false);

  useEffect(() => reset(selected), [selected, reset]);

  useEffect(() => !types.length && dispatch(fetchClassifierTypes()), [dispatch, types]);

  if (!selected) {
    return <></>;
  }

  const onSubmit = (data) => {
    if (data.id) {
      dispatch(save(data));
    } else {
      data.id = undefined;
      dispatch(add(data));
    }
    setShouldRefreshTable(true);
  };

  const onClose = () => {
    if (shouldRefreshTable) {
      dispatch(fetchBasicObjectTypes());
    }
    return false;
  };

  const handleOpenClassifier = () => {
    navigate(`/admin/classifiers/${selected.classifierType.code}`, { replace:true });
  };

  const title = selected.id ?
    t('admin.basicObjectType.form.label', { code: selected.code }) :
    t('admin.basicObjectType.form.labelNew');

  return <DialogForm
    title={<span>
      <Heading level='3'>{title}</Heading>
      {selected.classifierType &&
        <Button color="primary" size="small" onClick={handleOpenClassifier}>
          <Icon icon="arrowUpRight" color={Colors.sinineVaal} margin="6px" />
          {t('admin.basicObjectType.form.classifier', { code: selected.classifierType.code })}
        </Button>
      }
    </span>}
    onSubmit={handleSubmit(onSubmit)}
    onClose={onClose}
    disabled={isLoading}>
    <input type="hidden" name="id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={7} direction="column" spacing={4}>
          <Grid item sm={12}>
            <ClassifierSelect item={selected} name="domain" label="admin.basicObjectType.domain" required fullWidth
              disabled={isLoading || !!selected.id} control={control} errors={errors} classifierType={ClassifierType.domain}
              filter={(classifier) => [Domain.infrastructure, Domain.landscaping, Domain.environment].includes(classifier.itemCode)}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="code" required label="admin.basicObjectType.code"
              disabled={isLoading || !!selected.id} maxLength={50}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="title" required label="admin.basicObjectType.title"
              disabled={isLoading} maxLength={50}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="titleEng" required label="admin.basicObjectType.titleEng"
              disabled={isLoading} maxLength={50}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="note" multiline label="admin.basicObjectType.note"
              disabled={isLoading}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <ClassifierSelect item={selected} name="geometryType" label="admin.basicObjectType.geometryType" fullWidth required
              disabled={isLoading || !!selected.id} control={control} errors={errors} classifierType={ClassifierType.geometryType}
            />
          </Grid>
          <Grid item sm={12}>
            <VCheckbox name="showEstablishmentYear" label={t('admin.basicObjectType.showEstablishmentYear')}
              disabled={isLoading} control={control} errors={errors}  />
          </Grid>
          <Grid item sm={12}>
            <VCheckbox name="showInstallationMethod" label={t('admin.basicObjectType.showInstallationMethod')}
              disabled={isLoading} control={control} errors={errors}  />
          </Grid>
          <Grid item sm={12}>
            <VCheckbox name="restrictedGov" label={t('admin.basicObjectType.isRestrictedGov')}
              disabled={isLoading} control={control} errors={errors}  />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="labelDate" label="admin.basicObjectType.labelDate"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="labelDateEng" label="admin.basicObjectType.labelDateEng"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="labelText" label="admin.basicObjectType.labelText"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="labelTextEng" label="admin.basicObjectType.labelTextEng"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="labelClassifier" label="admin.basicObjectType.labelClassifier"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="labelClassifierEng" label="admin.basicObjectType.labelClassifierEng"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
          <ControlledSelect  
            label={t('admin.basicObjectType.extraClassifierType')}
            item={selected} 
            name="extraClassifierTypeCode" 
            control={control} 
            errors={errors} 
            disabled={isLoading}
            fullWidth
            displayClearValue>
            {types.map((classifierType, index) => (
              <MenuItem key={index} value={classifierType.code}>{classifierType.title}</MenuItem>
            ))}
          </ControlledSelect>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </DialogForm>;

}