import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { api } from '../../api/register';
import { Domain } from '../../constants/classifierConstants';
import RegisterUtils from '../../utils/RegisterUtils';
import TableUtils from '../../utils/TableUtils';
import { setActiveGeometries, showLayersByRegisterObjectType } from '../map/map';
import { showSuccess, showWarning, showWarnings } from '../notification';
import { reset } from './registerDocument';

const initialPageable = {
  pageSize: TableUtils.getPageSize(178),
  pageNumber: 0,
  sort: {
    field: "code",
    ascending: true
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'register',
  initialState: {
    objectType: null,
    objectRows: [],
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    objects: {},
    isLoading: false,
    isLoadingObjectType: null,
    formSaved: false,
    relationClassifiers: {},
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
      state.formSaved = false;
      state.isLoadingObjectType = action.payload
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    objectTypeSuccess: (state, action) => {
      state.objectType = action.payload.objectType;
      state.objectRows = [];
      state.pageable = cloneDeep(initialPageable);
      state.totalElements = null;
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
      } else {
        state.pageable.sort.field = action.payload.sortableField;
      }
      state.filter = action.payload.filter || {};
    },
    objectRowsSuccess: (state, action) => {
      if (state.isLoadingObjectType === action.payload.objectType) {
        const data = action.payload.data;
        state.objectRows = TableUtils.mergeArrayById(state.objectRows, data.content);
        state.pageable.last = data.last;
        state.totalElements = data.totalElements;
        state.pageable.loaded = true;
        state.isLoading = false;
        state.isLoadingObjectType = null;
      }
    },
    resetRowsSuccess: (state) => {
      state.pageable.pageNumber = 0;
      state.pageable.loaded = false;
      state.totalElements = null;
      state.objectRows = [];
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.objectRows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.objectRows = [];
        state.pageable.loaded = false;
      }
    },
    objectSuccess: (state, action) => {
      if (action.payload.clear) {
        state.objects = {};
      }
      state.objects[action.payload.objectType] = action.payload.data;
      state.isLoading = false;
    },
    objectDeletedSuccess: (state, action) => {
      state.objects[action.payload].deleted = true;
      state.isLoading = false;
    },
    relatedObjectsSuccess: (state, action) => {
      state.objects[action.payload.objectType].relatedObjects = Object.keys(action.payload.data).map(key => ({ objectType: key, count: action.payload.data[key], open: false, rows: [] }));
      state.isLoading = false;
    },
    toggleRelatedObjectSuccess: (state, action) => {
      const relatedObject = action.payload.relatedObject;
      if (state.objects[action.payload.objectType].relatedObjects) {
        state.objects[action.payload.objectType].relatedObjects.find(r => r.objectType === relatedObject.objectType).open = !relatedObject.open;
      }
    },
    relatedObjectRowsStartLoading: (state, action) => {
      if (state.objects[action.payload.objectType] && state.objects[action.payload.objectType].relatedObjects && state.objects[action.payload.objectType].relatedObjects.find(r => r.objectType === action.payload.relatedObjectType)) {
        state.objects[action.payload.objectType].relatedObjects.find(r => r.objectType === action.payload.relatedObjectType).isLoading = true;
      }
    },
    relatedObjectRowsSuccess: (state, action) => {
      const objectDef = RegisterUtils.getObjectDef(action.payload.relatedObjectType);
      const sortColumn = objectDef.columns.find(c => !c.detailsOnly && c.type !== 'relation');
      let rows = action.payload.data;
      if (sortColumn) {
        if (sortColumn.type === 'integer' || sortColumn.type === 'number') {
          rows = TableUtils.sortArrayByNumber(rows, sortColumn.field);
        } else {
          rows = TableUtils.sortArrayByString(rows, sortColumn.field);
        }
      }
      if (state.objects[action.payload.objectType].relatedObjects) {
        state.objects[action.payload.objectType].relatedObjects.find(r => r.objectType === action.payload.relatedObjectType).rows = rows;
        state.objects[action.payload.objectType].relatedObjects.find(r => r.objectType === action.payload.relatedObjectType).isLoading = false;
      } 
    },
    formSavedSuccess: (state, action) => {
      state.formSaved = action.payload;
      state.pageable.loaded = false;
      state.objectRows = [];
      state.isLoading = false;
    },
    relationClassifiersSuccess: (state, action) => {
      const rows = action.payload.data?.content || action.payload.data;
      state.relationClassifiers[action.payload.objectType] = rows;
    },
  },
});

export default slice.reducer;

// Actions

const {
  objectTypeSuccess,
  objectRowsSuccess,
  pageableSuccess,
  filterSuccess,
  objectSuccess,
  objectDeletedSuccess,
  resetRowsSuccess,
  relatedObjectsSuccess,
  toggleRelatedObjectSuccess,
  relatedObjectRowsStartLoading,
  relatedObjectRowsSuccess,
  formSavedSuccess,
  relationClassifiersSuccess,
  startLoading,
  hasError
} = slice.actions;

const handleError = (error, objectType) => async dispatch => {
  console.log(error);
  const response = error.response;
  let errorMsgs = [];
  if (response?.status === 404) {
    window.history.back();
    return;
  }
  if (response?.status === 400 && response.data && response.data.length) {
    response.data.forEach(error => {
      if (error.errorCode) {
        let errorMsg = {
          message: error.errorCode,
          parameters: {}
        };
        if (error.fieldName) {
          errorMsg.parameters.field = i18next.t(`register.${objectType}.${error.fieldName}`);
        }
        if (error.objectType) {
          errorMsg.parameters.objectType = i18next.t(`register.${error.objectType}.label`);
        }
        errorMsgs.push(errorMsg);
      } else {
        errorMsgs.push('error.technical');
      }
    });
  } else {
    errorMsgs.push('error.technical');
  }
  dispatch(hasError());
  dispatch(showWarnings(errorMsgs));
}

export const setObjectType = (objectType, filter) => async dispatch => {
  const objectDef = RegisterUtils.getObjectDef(objectType);
  let payload = { objectType, filter };
  if (objectDef.sort) {
    payload.sort = objectDef.sort;
  } else {
    const sortColumn = objectDef.sortByIndex ? objectDef.columns[objectDef.sortByIndex] :
      objectDef.columns.filter(c => !c.detailsOnly)[0];
    payload.sortableField = TableUtils.getSortField(sortColumn);
  }
  dispatch(objectTypeSuccess(payload));
}

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
}

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
}

export const setDetails = (objectType, selected) => async dispatch => {
  dispatch(objectSuccess({ objectType: objectType, data: selected }));
  dispatch(reset());
}

export const fetchObjectRows = (objectType, pageable, filter, pageSize) => async dispatch => {
  dispatch(startLoading(objectType));
  try {
    let params = `size=${pageSize || pageable.pageSize}&page=${pageable.pageNumber}`;
    await api.get(getFetchRowsPath(objectType, params, pageable, filter)).then((response) =>
      dispatch(objectRowsSuccess({ data: response.data, objectType: objectType })));
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
};

export const fetchObjectRowsWithIds = (objectType, ids) => async dispatch => {
  dispatch(startLoading(objectType));
  try {
    let params = `size=${ids.length}&page=0`;
    return await api.get(`${objectType}?${params}&search=id:${ids.join(",'id:")}`).then((response) => response.data?.content);
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
};

export const fetchObjectRowsCSV = (objectType, pageable, filter, pageSize) => async dispatch => {
  try {
    let params = `size=${pageSize}`;
    const rows = await api.get(getFetchRowsPath(objectType, params, pageable, filter)).then((response) => response.data?.content);
    return rows;
  }
  catch (e) {
    dispatch(handleError(e, objectType));
    return [];
  }
};

export const fetchDetails = (objectType, id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${RegisterUtils.getSuperObjectType(objectType)}/${id}`).then((response) => {
      dispatch(objectSuccess({ objectType: objectType, data: response.data }));
      dispatch(reset());
    });
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const fetchRelatedObjects = (objectType, id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${RegisterUtils.getSuperObjectType(objectType)}/${id}/related`).then((response) => dispatch(relatedObjectsSuccess({ objectType: objectType, data: response.data })));
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const toggleRelatedObject = (objectType, relatedObject) => async dispatch => {
  dispatch(toggleRelatedObjectSuccess({ objectType: objectType, relatedObject: relatedObject }));
}

export const fetchRelatedObjectRows = (objectType, id, relatedObject) => async dispatch => {
  dispatch(relatedObjectRowsStartLoading({ objectType: objectType, relatedObjectType: relatedObject.objectType }));
  try {
    await api.get(`${RegisterUtils.getSuperObjectType(objectType)}/${id}/${relatedObject.objectType}`).then((response) => dispatch(relatedObjectRowsSuccess({ objectType: objectType, relatedObjectType: relatedObject.objectType, data: response.data })));
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
};

export const addRegisterObject = (objectType, object) => async dispatch => {
  dispatch(startLoading());
  try {
    let path = RegisterUtils.getSuperObjectType(objectType);
    if (object.postApiPathPrefix) {
      path = object.postApiPathPrefix + path;
    }
    await api.post(path, object).then((response) => {
      dispatch(objectSuccess({ objectType: objectType, data: response.data, clear: true }));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const saveRegisterObject = (objectType, object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${RegisterUtils.getSuperObjectType(objectType)}/${object.id}`, object).then((response) => {
      dispatch(objectSuccess({ objectType: objectType, data: response.data, clear: true }));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const setInvalidRegisterObject = (objectType, object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${RegisterUtils.getSuperObjectType(objectType)}/${object.id}/setInvalid`).then((response) => {
      dispatch(objectSuccess({ objectType: objectType, data: response.data }));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const setValidRegisterObject = (objectType, object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${RegisterUtils.getSuperObjectType(objectType)}/${object.id}/setValid`).then((response) => {
      dispatch(objectSuccess({ objectType: objectType, data: response.data }));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const deleteRegisterObject = (objectType, object) => async dispatch => {
  dispatch(startLoading(objectDeletedSuccess));
  try {
    await api.delete(`${RegisterUtils.getSuperObjectType(objectType)}/${object.id}`, object).then((response) => {
      dispatch(objectDeletedSuccess(objectType));
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const fetchRelatedObjectsAndAddToFeatures = (objectType, object) => async dispatch => {
  try {
    await api.get(`${RegisterUtils.getSuperObjectType(objectType)}/${object.id}/related`).then((relatedObjectsResponse) => {
      if (relatedObjectsResponse?.data) {
        Object.keys(relatedObjectsResponse.data).forEach(relatedObjectType => {
          if (relatedObjectsResponse.data[relatedObjectType]) {
            api.get(`${RegisterUtils.getSuperObjectType(objectType)}/${object.id}/${relatedObjectType}`).then((response) => {
              if (response.data) {
                const geometryColumns = RegisterUtils.getRegisterGeometryColumns(relatedObjectType);
                if (geometryColumns.length) {
                  dispatch(setActiveGeometries(
                    geometryColumns.flatMap(geometryColumn => response.data.filter(i => i[geometryColumn.field]).map(i => i[geometryColumn.field])), true
                  ));
                }
              }
            });
          }
        });
      }
    });
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
};

export const fetchAllRowsAndAddToFeatures = (objectType, pageable, totalElements, filter) => async dispatch => {
  try {
    let params = `size=${totalElements}&page=0`;

    await api.get(getFetchRowsPath(objectType, params, pageable, filter)).then((response) => {
      const geometryColumns = RegisterUtils.getRegisterGeometryColumns(objectType);
      if (geometryColumns.length) {
        dispatch(setActiveGeometries(
          geometryColumns.flatMap(geometryColumn => response.data.content.filter(i => i[geometryColumn.field]).map(i => i[geometryColumn.field]))
        ));
      }
    });
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
}

export const setRegisterObjectGeometriesOnMap = (objectType, item, toggleLayers, showAllRelated) => async dispatch => {
  const geometries = RegisterUtils.getRegisterGeometries(objectType, item);
  if (geometries.length) {
    dispatch(setActiveGeometries(geometries));
  } else {
    dispatch(setActiveGeometries([]));
  }

  if (!!showAllRelated || RegisterUtils.getObjectDef(objectType).topLevelObject) {
    dispatch(fetchRelatedObjectsAndAddToFeatures(objectType, item));
  }
  toggleLayers && dispatch(showLayersByRegisterObjectType(objectType));
};

export const resetFormSaved = () => async dispatch => {
  dispatch(formSavedSuccess(false));
};

const getFetchRowsPath = (objectType, params, pageable, filter) => {
  let path = objectType;
  const superObjectType = RegisterUtils.getSuperObjectType(objectType);
  if (superObjectType === 'basicObject') {
    path = `basicObject/basicObjectType/${objectType}`;
  } else if (superObjectType === 'careArea') {
    const objectDef = RegisterUtils.getObjectDef(objectType);
    path = `careArea/domain/${objectDef.domain}`;
  }
  path += `?${params}&${TableUtils.filterToSearchParam(filter, RegisterUtils.getObjectDef(objectType).columns)}`;
  if (pageable.sort) {
    path += TableUtils.getSortParam(pageable.sort);
  }
  return path;
}

export const getRegisterObjectImageUrl = (objectType, id) => `${api.defaults.baseURL}/${objectType}/${id}/image`;

export const fetchRelationClassifiers = (objectType, objectDef) => async dispatch => {
  let path = `${objectType}`;
  if (objectType === 'cooperationPartnerCompany' && objectDef?.domain) {
    path = `/careArea/${objectType}/domain/${objectDef.domain}`;
  }
  if (objectType === 'careArea') {
    path += `/domain/${Domain.road}?page=0&size=300`;
  }
  try {
    await api.get(path).then((response) => {
      dispatch(relationClassifiersSuccess({ objectType: objectType, data: response.data }));
    });
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
};

export const postCustomAction = (objectType, id, action) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${RegisterUtils.getSuperObjectType(objectType)}/${id}/${action}`).then(() => {
      dispatch(formSavedSuccess(false));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
};

export const calculatePathToRainwaterRoute = (objectType, id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${RegisterUtils.getSuperObjectType(objectType)}/${id}/rainwaterRoutePath`).then((response) => {
      let routes = response.data;
      if (!routes?.length) {
        dispatch(showWarning('error.register.pathToRainwaterRouteNotFound'));
        dispatch(hasError());
        return;
      }
      if (routes.some(route => route.complete)) {
        routes = routes.filter(route => route.complete);
      } else {
        dispatch(showWarning('error.register.pathToRainwaterRouteBroken'));
      }
      dispatch(setActiveGeometries(routes.map(route => route.route)));
      dispatch(hasError());
    })
  }
  catch (e) {
    dispatch(handleError(e, objectType));
  }
};