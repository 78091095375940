export const ClassifierType = {
  positionSide: "asendi_pool",
  locationSide: "asukoha_pool",
  settlementUnit: "asustusuksus",
  documentType: "dokumendi_liik",
  applicationDocumentType: "taotluse_dokumendi_liik",
  dpDocumentGroup: "dp_dok_grupp",
  purposeGroup: "eesmargigrupp",
  purposeType: "eesmargi_liik",
  lampPurpose: "eesmark",
  weatherStationFunctions: "ilmajaama_funktsioonid",
  supportType: "jalandi_tuup",
  controlType: "juhtimise_liik",
  cablingType: "kaabelduse_tuup",
  cableMark: "kaabli_mark",
  wellKind: "kaevu_liik",
  wellMaterial: "kaevu_materjal",
  wellType: "kaevu_tuup",
  carrierType: "kanduri_liik",
  coatingLayering: "katte_kihilisus",
  coatingType: "katte_liik",
  shieldOwnership: "kilbi_kuuluvus",
  shieldType: "kilbi_liik",
  shieldPosition: "kilbi_paiknemine",
  pipeOutletStrengtheningType: "kindlustuse_liik",
  hasSidewalk: "konnitee_olemasolu",
  ditchType: "kraavi_liik",
  ownership: "kuuluvus",
  simpleObjectType: "lihtobjekti_tuup",
  additionalBoardRight: "lisatahvel_paremal",
  additionalBoardLeft: "lisatahvel_vasakul",
  countingPointType: "loenduspunkt_liik",
  switchingMethod: "lulitusviis",
  procedureType: "menetluse_liik",
  procedureRole: "menetluse_roll",
  formOfOwnership: "omandivorm",
  waitingRoomType: "ootekoja_tuup",
  position: "paiknemine",
  parkingLotSide: "parkla_pool",
  parkingLotType: "parklatuup",
  busStopType: "peatuse_liik",
  restrictionType: "piirangutuup",
  platformType: "platvormi_tuup",
  postType: "posti_tuup",
  cleanerType: "puhasti_liik",
  railwayCrossingType: "raudteeuletuskoha_liik",
  rainwaterRouteType: "sademeveetrassi_liik",
  conditionLevelSummer: "seisunditase_suvine",
  conditionLevelWinter: "seisunditase_talvine",
  servitudeType: "servituudi_liik",
  bridgeType: "sillatuup",
  travelDirection: "soidusuund",
  sizeGroup: "suurusgrupp",
  deadlineType: "tahtaja_liik",
  applicationType: "taotluse_liik",
  applicationStatus: "taotluse_olek",
  notificationType: "teavituse_liik",
  roadBreakdown: "tee_jaotus",
  roadKind: "tee_liik",
  roadSectionType: "tee_osa_liik",
  roadCondition: "tee_seisukord",
  roadType: "tee_tuup",
  crosswalkType: "teeuletuse_liik",
  crosswalkMarking: "teeuletuse_markeering",
  activityType: "tegevuse_liik",
  pipeType: "toru_liik",
  pipeMaterial: "toru_materjal",
  transportType: "transpordiliik",
  culvertType: "truubi_liik",
  culvertMaterial: "truubi_materjal",
  connectionPhases: "uhenduse_faasid",
  holderType: "valdaja_liik",
  domain: "valdkond",
  drainageBasinKind: "valgala_liik",
  drainageBasinType: "valgala_tuup",
  lampType: "valgusti_liik",
  lampCondition: "valgusti_seisund",
  lightReflectivity: "valgust_peegeldavus",
  finishingType: "viimistluse_liik",
  objectStatus: "objekti_olek",
  geometryType: "geomeetria_liik",
  installationMethod: "lihtobjekti_paigaldusviis",
  maintenanceGroup: "hooldegrupp",
  feederNumber: "fiider",
  rainwaterCodeAction: "rainwater_code_action",
  workOrderStatus: "tookasu_olek",
  priority: "prioriteet",
  clauseGroup: "TINGIMUSTE_GRUPP",
  clauseBlock: "TINGIMUS",
  applicantType: "osapoole_liik",
  coordinationDecision: "kooskolastuse_otsus",
  planningStatus: "planeeringu_olek",
  planningDocumentType: "planeeringu_dokumendi_liik",
  shortcut: "KIIRVIIT",
  planningOperationType: "DP_TOIMING",
  svmsWeatherDataType: "ILM_LIIK",
  svmsAlertType: "HAIRE_LIIK",
  careActionType: "teehoolde_liik"
};

export const ObjectStatus = {
  planned: "Planeeritav",
  valid: "Kehtiv",
  invalid: "Kehtetu"
};

export const Domain = {
  streetlight: "TV",
  road: "TE",
  rainwater: "SV",
  plan: "PL",
  infrastructure: "AR",
  landscaping: "HH",
  environment: "KI"
};

export const ApplicationType = {
  DP: "DP",
  KT: "KT",
  SVTT: "SVTT",
  TVTT: "TVTT",
  TPT: "TPT",
  TETT: "TETT",
  YP: "YP"
};

export const ApplicationStatus = {
  entered: "Sisestatud",
  submitted: "Esitatud",
  editing: "Taiendamisel",
  review: "Labivaatamisel",
  processing: "Menetluses",
  deleted: "Kustutatud",
  cancelled: "Taotlemisest_loobutud",
  declined: "Tagasi_lukatud",
  processed: "Menetletud",
  drafting: "Tingimused_koostamisel",
  coordinating: "Kooskolastamisel",
  signing: "Allkirjastamisel",
  initiated: "Algatatud",
  notInitiated: "Ei_algatatud",
  decisionYes: "Otsus",
  decisionNo: "Ei_otsus",
};

export const ApplicantType = {
  partyCoApplicant: "KAASTAOTLEJA",
  partyPlanner: "PLANEERIJA",
  owner: "OMANIK",
  applicant: "TAOT",
  proposer: "ETTEP_ESITAJA",
  involved: "KAASATUD"
};

export const ApplicationDecision = {
  process: "PROCESS",
  renew: "RENEW",
  reject: "REJECT",
  review: "REVIEW"
};

export const WorkOrderStatus = {
  entered: "Sisestatud",
  open: "Avatud",
  takenForFulfillment: "Taitmisele_voetud",
  fulfilled: "Taidetud",
  completed: "Lopetatud",
  invalid: "Kehtetu"
};

export const Priority = {
  operative4Hours: "1",
  urgent1Day: "2",
  important5Days: "3",
  ordinary15Days: "4",
  low30Days: "5"
};

export const WorkOrderFileType = {
  task: "TASK",
  completion: "COMPLETION"
};

export const ApplicationFileType = {
  plan: "PLAN"
};

export const ApplicationLocationType = {
  application: "application",
  plan: "plan",
  neighbour: "neighbour",
  party: "party"
};

export const ClauseBlockType = {
  template: "LYNKTEKST",
  templateFreetext: "LYNKTEKST_VABA",
  text: "TEKST",
  map: "KAART"
};

export const ApplicationDocumentType = {
  mandate: "VOLITUS",
  application: "TAOTLUS"
};

export const CoordinationDecision = {
  no: "kooskolastan_ei",
  yes: "kooskolastan_jah"
};

export const PlanningStatus = {
  initiated: "Algatatud",
  accepted: "Vastu_voetud",
  established: "10",
  completed: "Lopetatud",
  invalid: "20",
  partiallyValid: "15"
};

export const PlanningDocumentType = {
  initiate: "MD201",
  accept: "MD202",
  establish: "HO101",
  complete: "DL_LOPETAMINE",
  invalidate: "HO102",
  proposal: "MD303",
  opinion: "MD303",
  coordination: "MD304",
  notification: "MD400",
  draft: "DP_ESKIIS_ARUTELU",
  display: "DP_VALJAP",
  partiallyInvalidate: "DL_OSALISELT_KEHTETUKS",
  decisionNotInitiate: "DL_MITTE_ALGATAMINE",
  decisionInvalidate: "HO102",
  decisionNotInvalidate: "OTSUS_EI",
  courtDecision: "KO101",
  signedPlan: "DD100"
};

export const PlanningOperationType = {
  proposal: "DP_ETTEPANEK",
  coordination: "DP_KOOSK",
  opinion: "DP_SEISUK",
  decisionNotInitiate: "DL_MITTE_ALGATAMINE"
};