import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Authority } from '../../constants/authConstants';
import { Domain } from '../../constants/classifierConstants';
import SecureComponent from '../auth/SecureComponent';
import NavigationTabs from '../table/NavigationTabs';

export default function RegisterTabs() {
  const { t } = useTranslation();

  const renderNavLink = (domain) => <NavLink to={`/register/${domain}`}>{t(`register.${domain}.title`)}</NavLink>;
  return <NavigationTabs>
    {renderNavLink(Domain.rainwater)}
    {renderNavLink(Domain.streetlight)}
    {renderNavLink(Domain.road)}
    {renderNavLink(Domain.infrastructure)}
    {renderNavLink(Domain.landscaping)}
    <SecureComponent component={NavLink} to={`/register/${Domain.environment}`} authority={Authority.UC31_register_object_data}>
      {t(`register.${Domain.environment}.title`)}
    </SecureComponent>
    <SecureComponent component={NavLink} to="/register/servitude" authority={Authority.UC31_register_object_data}>
      {t('register.servitude.title')}
    </SecureComponent>
  </NavigationTabs>;
}