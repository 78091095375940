import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { TabContainer } from 'styleguide';
import { Domain } from '../../constants/classifierConstants';
import SecureComponent from '../auth/SecureComponent';
import { Authority } from '../../constants/authConstants';

export default function ReportTabs() {
  const { t } = useTranslation();
  const mobile = useSelector(state => state.global.mobileView);

  const renderNavLink = (domain) => <NavLink to={`/report/${domain}`}>{t(`report.tabs.${domain}`)}</NavLink>;
  return <TabContainer size={mobile ? 'small' : 'big'}>
    {renderNavLink(Domain.rainwater)}
    {renderNavLink(Domain.streetlight)}
    {renderNavLink(Domain.road)}
    {renderNavLink(Domain.infrastructure)}
    {renderNavLink(Domain.landscaping)}
    <SecureComponent component={NavLink} to={`/report/${Domain.environment}`} authority={Authority.UC31_register_object_data}>
      {t(`report.tabs.${Domain.environment}`)}
    </SecureComponent>
    {renderNavLink("other")}
  </TabContainer>;
}